export const environment = {
  production: false,
  firebase: {
    projectId: 'enroll-here-demo',
    appId: '1:603279095926:web:5345f42461e40f85010757',
    storageBucket: 'enroll-here-demo.appspot.com',
    apiKey: 'AIzaSyB_YTDIbdb0_PHzabIzPDiburOhFCGClj8',
    authDomain: 'enroll-here-demo.firebaseapp.com',
    messagingSenderId: '603279095926',
    measurementId: 'G-LHZ5LBZTCV',
    appCheckRecaptchaSiteKey: '6Lfzep0pAAAAAFW4A29ksefO_SBawxB3Nc16K-MP',
  },
  googleMapsApiKey: 'AIzaSyB_YTDIbdb0_PHzabIzPDiburOhFCGClj8',
  allowedPostLoginTargets: [
    'https://enroll-here-demo-landing.web.app',
    'https://localhost:4501',
  ],
  homepage: 'https://enroll-here-demo-landing.web.app',
  isDemo: true,
  elasticHttp: 'https://enrollhere.es.us-east1.gcp.elastic-cloud.com/',
  elasticPrefix: 'enrollhere-demo_',
  devVersion: 'dev_24-08-04-05:33',
  sentry: {
    dsn: 'https://edda11464f7ff219d68e15c9b41290ad@o4506558808064000.ingest.us.sentry.io/4506700804849664',
    tracePropagationTargets: [/^https:\/\/enroll-here-demo\.web\.app/],
    environment: 'demo',
  },
};
